import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
    estadoPagina: -1, // 0: cargando, 1: cargado, 2: error, 3: enviado correctamente, 4: error en el envío
    datosEncuesta: null, 
    envioFallido: 0, // Nueva propiedad para manejar el fallo en el envío (0: sin fallo, 1: fallo)
    detalleEnvio: "", // Nueva propiedad para almacenar el detalle del error
    contenidoEnvio: null, // Nueva propiedad para almacenar el contenido del envío
    hashEncuesta: "", // Nueva propiedad para almacenar el hash de la encuesta
};

const cambiarEstadoPagina = (state, action) => {
    return updateObject(state, {
        estadoPagina: action.estado,
    });
};

const guardarDatosEncuesta = (state, action) => {
    return updateObject(state, {
        datosEncuesta: action.datos,
        envioFallido: action.datos.envioFallido !== undefined ? action.datos.envioFallido : state.envioFallido,
        detalleEnvio: action.datos.detalleEnvio || state.detalleEnvio,
    });
};

// Función para guardar el contenido del envío
const guardarContenidoEnvio = (state, action) => {
    return updateObject(state, {
        contenidoEnvio: action.contenido,
    });
};

// Nueva función para actualizar el hashEncuesta
const actualizarHashEncuesta = (state, action) => {
    return updateObject(state, {
        hashEncuesta: action.hash,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CAMBIAR_ESTADO_PAGINA:
            return cambiarEstadoPagina(state, action);
        case actionTypes.GUARDAR_DATOS_ENCUESTA:
            return guardarDatosEncuesta(state, action);
        case actionTypes.GUARDAR_CONTENIDO_ENVIO: // Acción para guardar el contenido del envío
            return guardarContenidoEnvio(state, action);
        case actionTypes.ACTUALIZAR_HASH_ENCUESTA: // Nueva acción para actualizar el hashEncuesta
            return actualizarHashEncuesta(state, action);
        default:
            return state;
    }
};

export default reducer;
