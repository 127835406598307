// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Estilo global para el fondo de la página */\r\n\r\n/* Cabecera */\r\n.formularioEncuesta-module__header__o7wJs,\r\n.formularioEncuesta-module__footer__yKNoE {\r\n      height: 100px;\r\n      display: flex;\r\n      justify-content: center;\r\n      align-items: center;\r\n      font-size: 24px;\r\n}\r\n\r\n/* Contenedor de las columnas */\r\n.formularioEncuesta-module__columnas__fIFBq {\r\n      display: flex;\r\n}\r\n\r\n/* Cada columna ocupa el 50% */\r\n.formularioEncuesta-module__columnaIzq__6c9aa {\r\n      width: 50%; /*50%*/ /*300px*/ /* Cambiar acá */\r\n      /*height: 300px; /* Ajusta la altura según sea necesario */\r\n      display: flex;\r\n      justify-content: center;\r\n      /*align-items: center;*/\r\n      padding-top: 20px; padding-left: 20px; padding-bottom: 20px;\r\n      padding-right: 20px;\r\n      /*font-size: 20px;*/\r\n}\r\n\r\n/* Cada columna ocupa el 50% */\r\n.formularioEncuesta-module__columnaDer__5htAp {\r\n      width: 50%; /*50%*/ /*600px*/ /* Cambiar acá */\r\n      /*height: 300px; /* Ajusta la altura según sea necesario */\r\n      display: flex;\r\n      justify-content: center;\r\n      /*align-items: center;*/\r\n      padding-top: 20px;\r\n      padding-left: 20px;\r\n      padding-bottom: 20px;\r\n      padding-right: 20px;    \r\n      /*font-size: 20px;*/\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "formularioEncuesta-module__header__o7wJs",
	"footer": "formularioEncuesta-module__footer__yKNoE",
	"columnas": "formularioEncuesta-module__columnas__fIFBq",
	"columnaIzq": "formularioEncuesta-module__columnaIzq__6c9aa",
	"columnaDer": "formularioEncuesta-module__columnaDer__5htAp"
};
export default ___CSS_LOADER_EXPORT___;
