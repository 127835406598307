// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DetalleEncuesta-module__infobox__tCUcI {\r\n    margin-top: 7px;\r\n    margin-left: 7px;\r\n    padding: 15px;\r\n    text-align: left;\r\n}\r\n\r\n.DetalleEncuesta-module__p__m3BdL {\r\n    margin: 5px 0;\r\n}\r\n\r\n/* Infobox */\r\n\r\n.DetalleEncuesta-module__infocard__p6SCZ {\r\n    background-color: #515151;\r\n    /* #f0ff9e;*/\r\n    color: white;\r\n    padding: 10px;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\r\n    justify-content: left;\r\n    text-align: left;\r\n}\r\n\r\n.DetalleEncuesta-module__infocard__p6SCZ h2 {\r\n    margin-top: 5px;\r\n    margin-bottom: 10px;\r\n    color: #f1a208;\r\n}\r\n\r\n.DetalleEncuesta-module__infocard__p6SCZ b {\r\n    color: #f1a208;\r\n}\r\n\r\n\r\n.DetalleEncuesta-module__infocard__p6SCZ p {\r\n    margin-bottom: 10px;\r\n    /*color: #666;*/\r\n}\r\n\r\n.DetalleEncuesta-module__infocard__p6SCZ ul {\r\n    list-style-type: none;\r\n    /*color: #333;*/\r\n}\r\n\r\n/* Zona de error */\r\n.DetalleEncuesta-module__error__GdPLe {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: center;\r\n    height: 100%;\r\n    width: 100%;\r\n    /* Asegúrate de que ocupe todo el ancho */\r\n    flex-direction: column;\r\n    grid-gap: 10px;\r\n    gap: 10px;\r\n}\r\n\r\n.DetalleEncuesta-module__error__GdPLe h2,\r\n.DetalleEncuesta-module__error__GdPLe p {\r\n    display: inline-block;\r\n    /* Cambia de bloque a inline-block */\r\n    margin: 0 10px;\r\n    /* Espaciado entre elementos */\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infobox": "DetalleEncuesta-module__infobox__tCUcI",
	"p": "DetalleEncuesta-module__p__m3BdL",
	"infocard": "DetalleEncuesta-module__infocard__p6SCZ",
	"error": "DetalleEncuesta-module__error__GdPLe"
};
export default ___CSS_LOADER_EXPORT___;
