// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cabecera-module__cuerpito__bapdu {\r\n    margin: 0;\r\n    padding: 0;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.Cabecera-module__container__xYlDh {\r\n    width: 750px /* 750px 900px*/;\r\n    height: 100%;\r\n    display: flex;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.Cabecera-module__left__jV8kT,\r\n.Cabecera-module__right__TY9Ip {\r\n    display: flex;\r\n    align-items: center;\r\n    box-sizing: border-box;\r\n    /* Asegura que el padding y los bordes no cambien el ancho */\r\n}\r\n\r\n.Cabecera-module__left__jV8kT {\r\n    width: 30%;\r\n    margin-left: 10px;\r\n    text-align: center;\r\n    justify-content: center;\r\n}\r\n\r\n.Cabecera-module__right__TY9Ip {\r\n    width: 70%; \r\n    text-align: right;\r\n    font-weight: bold;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n}\r\n\r\n.Cabecera-module__letraGrande__LLF2J{\r\n      font-size: 20px;\r\n}\r\n\r\n.Cabecera-module__letraChica__UPpAd {\r\n     font-size: 15px;\r\n}\r\n\r\n.Cabecera-module__left__jV8kT img {\r\n    max-width: 100%;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cuerpito": "Cabecera-module__cuerpito__bapdu",
	"container": "Cabecera-module__container__xYlDh",
	"left": "Cabecera-module__left__jV8kT",
	"right": "Cabecera-module__right__TY9Ip",
	"letraGrande": "Cabecera-module__letraGrande__LLF2J",
	"letraChica": "Cabecera-module__letraChica__UPpAd"
};
export default ___CSS_LOADER_EXPORT___;
