// Componente que muestra el banner publicitario en el footer
// ============
import React from 'react';
// ============
import classes from './Publicidad.module.css';

const Publicidad = ({ ocultar }) => {
      return (
           <div>
                <div className={classes.parent}>
                     <div className={classes.overlay}>
                          {ocultar !== 1 && (
                               <a href="https://www.gauss-sistemas.com.ar/eiffel-erp/" target="_blank" rel="noopener noreferrer">
                                    <img src="/img/EiffelPublicidad.png" alt="Gauss" />
                               </a>
                          )}
                     </div>
                </div>
           </div>
      );
};

export default Publicidad;
