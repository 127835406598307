import { baseUrlEncuestas } from '../../shared/urlApps';
import * as actionTypes from './actionTypes';

// Cambia el estado de la página
export const cambiarEstadoPagina = (estado) => {
    return {
        type: actionTypes.CAMBIAR_ESTADO_PAGINA,
        estado,
    };
};

// Actualizar el hash de la encuesta
export const actualizarHashEncuesta = (hash) => {
    return {
        type: actionTypes.ACTUALIZAR_HASH_ENCUESTA,
        hash,
    };
};

// Guarda los datos de la encuesta en el estado
export const guardarDatosEncuesta = (datos) => {
    return {
        type: actionTypes.GUARDAR_DATOS_ENCUESTA,
        datos,
    };
};

// Obtener los datos de la encuesta
export const obtenerDatosEncuesta = (hash) => {
    return async (dispatch) => {
        dispatch(cambiarEstadoPagina(0)); // Cambia estado a "cargando"
        
        try {
            const url = `${baseUrlEncuestas()}encuestas?hash=${hash}`;

            console.log("Intentando hacer un fetch a " + url);

            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            const jsonData = await response.json();
            dispatch(guardarDatosEncuesta(jsonData));
            dispatch(cambiarEstadoPagina(1)); // Cambia estado a "cargado"
        } catch (error) {
            // Guarda el mensaje de error en datosEncuesta 
            dispatch(guardarDatosEncuesta({ error: error.message }));
            dispatch(cambiarEstadoPagina(2)); // Cambia estado a "error"
        }
    };
};

// Nueva acción para enviar las respuestas de la encuesta
export const enviarRespuestasEncuesta = (data) => {
    return async (dispatch) => {
        try {
            console.log("Intentando enviar las respuestas...");
            console.log("Contenido:")
            console.log(JSON.stringify(data))

            const response = await fetch(`${baseUrlEncuestas()}encuestas/Respuesta`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            // Verifica si la respuesta es exitosa o si es un código 204
            if (!response.ok && response.status !== 204) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            let responseData;
            if (response.status !== 204) {
                responseData = await response.json();
                console.log('Respuestas enviadas correctamente:', responseData);
            } else {
                console.log('Respuestas enviadas correctamente con código 204');
            }

            // Cambiar el estado de la página a "enviado correctamente"
            dispatch(cambiarEstadoPagina(3));

        } catch (error) {
            console.error('Error al enviar las respuestas:', error.message);

            // Si falla, actualizar los valores de envioFallido y detalleEnvio
            dispatch({
                type: actionTypes.GUARDAR_DATOS_ENCUESTA,
                datos: { envioFallido: 1, detalleEnvio: error.message }
            });

            // Cambiar el estado de la página a "error en el envío"
            dispatch(cambiarEstadoPagina(4));
        }
    };
};
