// Componente que genera la tarjeta de Detalles de la encuesta
// ============
import React, { forwardRef } from 'react';
// ============
import classes from './DetalleEncuesta.module.css';

const DetalleEncuesta = forwardRef(function DetalleEncuesta({fechaSolicitud, fechaCierre, cliente, SolicitadaPor, RealizadaPor, Tema, Detalle, numeroEncuesta, mensajeBienvenida }) {
      
      // Verifica si todas las props están vacías
      const allPropsEmpty = !fechaSolicitud && !fechaCierre && !cliente && !SolicitadaPor && !RealizadaPor && !Tema && !Detalle && !numeroEncuesta && !mensajeBienvenida;

      // Le damos formato a la fecha
      const generarFecha = (fcruda) => {
           let fechaString = fcruda.slice(0, 10); // Extraer sólo los primeros 10 caracteres para la fecha
           console.log(fechaString); 
           let [year, month, day] = fechaString.split("-"); // Separar año, mes y día
           let formato = `${day}/${month}/${year}`;
           console.log(formato); 
           return formato;
      }

      // Return del componente
      return (
           <div>
                {allPropsEmpty ? ( // Mensaje de error en caso de que la información de los detalles de la encuesta esté incompleta
                     <div className={classes.error}>
                          <div><h2>Falta información</h2></div>
                     <div>
                          <p><b>Si estás viendo este mensaje es porque faltan detalles de la encuesta.</b></p>
                          </div><div>
                          <p><b>Por favor, reportá esto al soporte técnico.</b></p>
                     </div>
                </div>
           ) : (
                <div className={classes.infocard}>
                     <>
                          <h2>¿Nos das tu opinión?</h2>
                          {mensajeBienvenida? (<p>{mensajeBienvenida}<br /></p>):("")}
                          <p><b>Cliente: </b>{cliente}<br /></p>
                          <p><b>Tarea: </b>{Tema}<br /></p>
                          {/*<p><b>Detalle: </b>{Detalle}<br /></p>*/}
                          <p><b>Solicitada por: </b>{SolicitadaPor}<br /></p>
                          <p><b>Realizada por: </b>{RealizadaPor}<br /></p>
                          <p><b>Fecha de solicitud: </b>{generarFecha(fechaSolicitud)}<br /></p>
                          <p><b>Fecha de cierre: </b>{generarFecha(fechaCierre)}<br /></p>
                          {/*<p><b>Orden de trabajo N°:</b> {numeroEncuesta}<br /></p>*/}
                     </>
                </div>
           )}
           </div>
      );
});

export default DetalleEncuesta;