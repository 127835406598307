import React from "react";
import { connect } from 'react-redux';
import classes from './Informes.css';
import { Grid, Typography } from "@material-ui/core";
import BoxEjecutarPaginaInfo from '../../components/UI/BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo';
import InformesFiltros from "../../components/Indicadores/Informes/InformesFiltros";
import InformesListado from "../../components/Indicadores/Informes/InformesListado";
import InformesPaginado from "../../components/Indicadores/Informes/InformesPaginado";

const Informes = (props) => {
  const { ejecucionPagina } = props;

  return !ejecucionPagina ? (
    <BoxEjecutarPaginaInfo />
  ) : (
    <Grid container spacing={1} className={classes.panelContainer}>
      <Grid item xs={12}>
        <Typography variant="h5">
          Listado de informes
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={1} className={classes.containerFlexRow}>
        <InformesFiltros />
      </Grid>
      <Grid item xs={12} container spacing={1} justifyContent="center">
        <InformesListado />
      </Grid>
      <Grid item xs={12} container spacing={1} justifyContent="center">
        <InformesPaginado />
    </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    ejecucionPagina: state.ejecucionPaginas.ejecucionInformes,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Informes);