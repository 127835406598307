import React from 'react';
import {  withRouter, useLocation } from 'react-router-dom';

import { pathNamePages } from './shared/pathnames';
import AppDashboard from './AppDashboard';
import AppEncuestas from './AppEncuestas';

const App = () => {
  const { pathname } = useLocation();

  return pathname !== pathNamePages.encuestas ?
    (
      <AppDashboard />
    ) : (
      <AppEncuestas />
    );
};

export default withRouter(App); 