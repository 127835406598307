import React, { Fragment, useEffect, useState } from 'react';
import { storageMonedaSigno } from '../../../../shared/sessionData';
import { formatNumberWithMonedaSigno } from '../../../../shared/formatNumber';
import TableDetallesComprobante from '../TablaDetallesComprobante/TablaDetallesComprobante';
import AccordionObservacionesComprobante from '../AccordionObservacionesComprobante/AccordionObservacionesComprobante';
import moment from 'moment';
import { Grid, IconButton } from '@material-ui/core';
import * as actions from '../../../../store/actions';
import CloseIcon from '@material-ui/icons/Close';
import classes from './DetallesComprobanteVenta.css';
import { connect } from 'react-redux';
import Spinner from '../../../UI/Spinner/Spinner';
import LabelGridTitleDescription from '../../../UI/LabelGridTitleDescription/LabelGridTitleDescription';

const DetallesComprobanteVenta = (props) => {
  const { onLoadDetallesComprobante, onChangeModalBody, comprobante } = props;

  const { fecha, referencia, numeroExterno, idSucursal, idSistema, idComproba, idEmpresa } = comprobante;
  const [loading, setLoading] = useState(false);
  const [comprobanteLocal, setComprobanteLocal] = useState({
    fecha: '',
    referencia: '',
    numeroExterno: '',
    idSucursal: '',
    idSistema: '',
    cliente: '',
    idComproba: '',
    detalleComprobante: [],
    subtotal: 0,
    bonificacion1: 0,
    porcBonif1: 0.0,
    bonificacion2: 0,
    porcBonif2: 0.0,
    subtotalBonificado: 0,
    impuestos: 0,
    importe: 0,
    direccion: '',
    localidad: '',
    provincia: '',
    cotizacion: 0,
    simboloMoneda: '$'
  });

  useEffect(() => {
    setLoading(true);
    onLoadDetallesComprobante(
      idSucursal,
      idSistema,
      idComproba,
      referencia,
      idEmpresa,
      (detallesComprobante) => {
        setComprobanteLocal(detallesComprobante);
        setLoading(false);
      },
      (error) => {
        onChangeModalBody(<div style={{ textAlign: 'center' }}>Error al cargar los detalles del comprobante</div>);
        setLoading(false);
      }
    );
  }, []);

  const buildInfoTotal = () => {
    const titlePorcBonif1 = comprobanteLocal.porcentajeBonificacion1
      ? `B. 1 (${comprobanteLocal.porcentajeBonificacion1})`
      : 'B. 1 (0.00)';
    const titlePorcBonif2 = comprobanteLocal.porcentajeBonificacion2
      ? `B. 2 (${comprobanteLocal.porcentajeBonificacion2})`
      : 'B. 2 (0.00)';

    return (
      <div className={classes.contenedorTotales}>
        <Grid container>
          <Grid container item xs={11} justifyContent="flex-start" spacing={4}>
            <TitleMontoTotales title="Subtotal" monto={comprobanteLocal.subtotal} simbolo={comprobanteLocal.simboloMoneda}/>
            <TitleMontoTotales title={titlePorcBonif1} monto={comprobanteLocal.bonificacion1} simbolo={comprobanteLocal.simboloMoneda}/>
            <TitleMontoTotales title={titlePorcBonif2} monto={comprobanteLocal.bonificacion2} simbolo={comprobanteLocal.simboloMoneda}/>
            <TitleMontoTotales title="Subtotal" monto={comprobanteLocal.subtotalBonificado} simbolo={comprobanteLocal.simboloMoneda}/>
            <TitleMontoTotales title="Impuestos" monto={comprobanteLocal.impuestos} simbolo={comprobanteLocal.simboloMoneda}/>
          </Grid>
          <Grid container item xs={1} justifyContent="flex-end">
            <TitleMontoTotales title="Total" monto={comprobanteLocal.importe} simbolo={comprobanteLocal.simboloMoneda}/>
          </Grid>
        </Grid>
      </div>
    );
  };

  const TitleMontoTotales = ({ title, monto, xs, simbolo } = props) => {
    return (
      <Grid item xs={xs} className={classes.titleDescriptionContainer}>
        <Grid item className={classes.titleTotales}>
          {title}
        </Grid>
        <Grid item className={classes.montoTotales}>
          {formatNumberWithMonedaSigno(monto, simbolo)}
        </Grid>
      </Grid>
    );
  };

  const InfoComprobanteContainer = (props) => {
    return (
      <Grid className={classes.infoComprobanteContainer} container spacing={1}>
        {props.children}
      </Grid>
    );
  };

  const InfoComprobanteRow = (props) => {
    return (
      <Grid className={classes.infoComprobanteRow} container item xs={12} spacing={5}>
        {props.children}
      </Grid>
    );
  };

  const buildInfoComprobante = () => {
    return (
      <InfoComprobanteContainer>
        <InfoComprobanteRow>
          <LabelGridTitleDescription title={'Cliente'} description={comprobanteLocal.cliente.trim()} xs={3} />
          <LabelGridTitleDescription title="Comprobante" description={comprobanteLocal.comprobante} xs={2} />
          <LabelGridTitleDescription title="Número" description={numeroExterno} xs={3} />
          <LabelGridTitleDescription title="Fecha" description={moment(fecha).format('DD/MM/YY')} xs={1} />
          <LabelGridTitleDescription title="Cond. de pago" description={comprobanteLocal.condicionPago} xs={2} />
        </InfoComprobanteRow>
        <InfoComprobanteRow>
          <LabelGridTitleDescription title="Dirección" descriptionSmall={comprobanteLocal.direccion.trim()} xs={3} />
          <LabelGridTitleDescription title="Localidad" descriptionSmall={comprobanteLocal.localidad.trim()} xs={2} />
          <LabelGridTitleDescription title="Provincia" descriptionSmall={comprobanteLocal.provincia.trim()} xs={3} />
          <LabelGridTitleDescription title="Referencia" descriptionSmall={referencia} xs={1} />
          <LabelGridTitleDescription title="Estado comprobante" descriptionSmall={comprobanteLocal.estado} xs={2} />
        </InfoComprobanteRow>
      </InfoComprobanteContainer>
    );
  };

  const buildModalHeader = () => {
    return (
      <div className={classes.TitleCard}>
        <p style={{ fontWeight: 'bold', marginBottom: '1px' }}>DETALLE DE COMPROBANTE</p>
        <div>
          <IconButton aria-label="close" onClick={() => props.onCloseShowModal()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  const buildModalFooter = () => {
    let monto = formatNumberWithMonedaSigno(comprobante.monto, storageMonedaSigno());
      if(comprobante.debe || comprobante.haber)
      {
        if(comprobante.debe)
          monto = formatNumberWithMonedaSigno(0, storageMonedaSigno()) === comprobante.debe ? comprobante.haber : comprobante.debe
        else
          monto = formatNumberWithMonedaSigno(comprobante.monto, storageMonedaSigno())
      }
    return (
      <InfoComprobanteContainer>
        <InfoComprobanteRow>
          <LabelGridTitleDescription
            title="Confección"
            descriptionSmall={moment(comprobanteLocal.fechaConfeccion).format('DD/MM/YY')}
            xs={1}
          />
          <LabelGridTitleDescription title="Cotización" descriptionSmall={`${formatNumberWithMonedaSigno(1, comprobanteLocal.simboloMoneda)} = ${formatNumberWithMonedaSigno(comprobanteLocal.cotizacion, '$')}`} xs={2} />
          <LabelGridTitleDescription title={`Total sin impuestos ${storageMonedaSigno()}`} descriptionSmall={`${monto}`}/>
        </InfoComprobanteRow>
      </InfoComprobanteContainer>
    );
  };

  return (
    <Fragment>
      <div style={{ opacity: loading && 0.5 }}>
        {buildModalHeader()}
        {buildInfoComprobante()}
        <div style={{ marginBottom: '10px' }} />
        {comprobanteLocal.observaciones && comprobanteLocal.observaciones.length !== 0 && (
          <AccordionObservacionesComprobante observaciones={comprobanteLocal.observaciones} />
        )}
        <TableDetallesComprobante articulos={comprobanteLocal.detalleComprobante} simbolo={comprobanteLocal.simboloMoneda}/>
        <div style={{ marginBottom: '0px' }} />
        {buildInfoTotal()}
        <hr />
        {buildModalFooter()}
      </div>
      {loading && (
        <div className={classes.Spinner}>
          <Spinner />
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  showModal: state.modals.globalModal.show
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseShowModal: () => dispatch(actions.showGlobalModal(false, '', undefined, undefined, true, true , 'lg')),
    onChangeModalBody: (newBody) => dispatch(actions.changeGlobalModalBody(newBody)),
    onLoadDetallesComprobante: (idSucursal, idSistema, idComproba, nroReferencia, idEmpresa, success, errorFail) =>
      dispatch(actions.loadDetalleComprobante(idSucursal, idSistema, idComproba, nroReferencia, idEmpresa, success, errorFail))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetallesComprobanteVenta);
