// Componente encargado de armar las páginas de errores e información
// ============
import React from 'react';
// ============
import {useDispatch, useSelector } from 'react-redux';
import {enviarRespuestasEncuesta } from '../../../store/actions/encuestas';
// ============
import classes from './MensajeInfoerror.module.css';
// ============
import Cabecera from "../../../components/Encuestas/Cabecera/Cabecera";
import Publicidad from "../../../components/Encuestas/Publicidad/Publicidad";
import Button from '@mui/material/Button';

const MensajeInfoerror = ({ mensaje }) => {
      let url;
      switch (mensaje?.imagen) {
           case 0:
                url = "/img/PersonajeCorriendo.jpg";
                break;
           case 1:
                url = "/img/PersonajeConfundido.jpg";
                break;
           case 2:
                url = "/img/PersonajeFeliz.jpg";
                break;
           default:
                break;
      }

      // Handler para reintentar el envío
      const dispatch = useDispatch();
      const contenidoEnvio = useSelector((state) => state.encuestas.contenidoEnvio); // Obtener contenidoEnvio
      const handleReintentarEnvio = () => {
           if (contenidoEnvio) {
                dispatch(enviarRespuestasEncuesta(contenidoEnvio)); // Reintentar con el contenido de envío guardado
           }
      };

      // Return del componente
      return (
           <>
                <div className={classes.header}>
                     <Cabecera numeroEncuesta={""} />
                </div>
                <div className={classes.columns}>
                     <div className={classes.column}>
                          <div className={classes.error}>
                               <div><h2>{mensaje.titulo}</h2></div>
                               <div><p><b>{mensaje.texto1}</b></p></div>
                               {mensaje?.error?.texto && (
                                    <div>
                                         <b>Detalle: <span style={{ color: mensaje.error.color }}>{mensaje.error.texto}</span><br /></b>
                                    </div>
                               )}
                               {mensaje?.texto2 && (
                                    <div>
                                         <p><b>{mensaje.texto2}</b></p>
                                    </div>
                               )}
                               {mensaje?.mostrarBotonReenviar && (
                                    <div>
                                         <Button
                                              variant="contained"
                                              sx={{ 
                                                   width: '150px', 
                                                   backgroundImage: 'linear-gradient(45deg, rgb(251, 176, 64), rgb(241, 90, 41))', 
                                                   color: 'white', '&:hover': { backgroundColor: '#083953' } 
                                              }}
                                              onClick={handleReintentarEnvio}
                                         >
                                              Reintentar
                                         </Button>
                                    </div>
                               )}
                          </div>
                     </div>
                     <div className={classes.column}>
                          <div className={classes.error}>
                               <p><img src={url} width="300px" alt="" /></p>
                          </div>
                     </div>
                </div>
                <div className={classes.footer}>
                     <Publicidad ocultar={1} />
                </div>
           </>
      );
};

export default MensajeInfoerror;