// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Estilo global para el fondo de la página */\r\n\r\n/* Cabecera */\r\n.MensajeInfoerror-module__header__jRSI3,\r\n.MensajeInfoerror-module__footer__Xt59b {\r\n    height: 100px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-size: 24px;\r\n}\r\n\r\n/* Contenedor de las columnas */\r\n.MensajeInfoerror-module__columns__B2nw\\+ {\r\n    display: flex;\r\n}\r\n\r\n/* Cada columna ocupa el 50% */\r\n.MensajeInfoerror-module__column__Yfi72 {\r\n    width: 50%;\r\n    /*height: 300px; /* Ajusta la altura según sea necesario */\r\n    display: flex;\r\n    justify-content: center;\r\n    /*align-items: center;*/\r\n    padding: 20px;\r\n    /*font-size: 20px;*/\r\n}\r\n\r\n/* Zona de error */\r\n.MensajeInfoerror-module__error__wj8Pi {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: center;\r\n    height: 100%;\r\n    width: 100%;\r\n    /* Asegúrate de que ocupe todo el ancho */\r\n    flex-direction: column;\r\n    grid-gap: 10px;\r\n    gap: 10px;\r\n}\r\n\r\n.MensajeInfoerror-module__error__wj8Pi h2,\r\n.MensajeInfoerror-module__error__wj8Pi p {\r\n    display: inline-block;\r\n    /* Cambia de bloque a inline-block */\r\n    margin: 0 10px;\r\n    /* Espaciado entre elementos */\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "MensajeInfoerror-module__header__jRSI3",
	"footer": "MensajeInfoerror-module__footer__Xt59b",
	"columns": "MensajeInfoerror-module__columns__B2nw+",
	"column": "MensajeInfoerror-module__column__Yfi72",
	"error": "MensajeInfoerror-module__error__wj8Pi"
};
export default ___CSS_LOADER_EXPORT___;
