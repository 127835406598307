// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Publicidad-module__parentdiv__i5yMc {\r\n    position: relative;\r\n    height: 100vh;\r\n    /* Ocupa todo el alto de la pantalla */\r\n    width: 100vw;\r\n    /* Ocupa todo el ancho de la pantalla */\r\n    /*background-image: url('EiffelPublicidad.png');  Ruta a la imagen de fondo */\r\n    background-size: cover;\r\n    /* La imagen cubre todo el DIV */\r\n    background-position: center;\r\n    /* Centrar la imagen en el DIV */\r\n}\r\n\r\n.Publicidad-module__overlaydiv__MTGTL {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    height: 100%;\r\n    width: 100%;\r\n    background-color: rgba(255, 165, 0, 0.5);\r\n    /* Capa naranja semi-traslúcida */\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentdiv": "Publicidad-module__parentdiv__i5yMc",
	"overlaydiv": "Publicidad-module__overlaydiv__MTGTL"
};
export default ___CSS_LOADER_EXPORT___;
