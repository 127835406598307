import React from 'react';
import classes from '../FotoDelDia/FotoDelDia.css';
import EncuestasPrincipal from "./EncuestasPrincipal.js"

const Encuestas = () => {
  sessionStorage.setItem('seccionNombre', 'Encuestas');

  return (
    <div className={classes.fotoDelDiaContainer}>
      <EncuestasPrincipal/>
    </div>
  );
};

export default Encuestas;