// Componente encargado de armar el formulario con las preguntas
// ============
import React, { useState} from 'react';
// ============
import classes from './Preguntas.module.css';
// ============
import PreguntaTexto from "./PreguntaTexto/PreguntaTexto";
import PreguntaSiNo from "./PreguntaSiNo/PreguntaSiNo";
import PreguntaMenu from "./PreguntaMenu/PreguntaMenu";
import PreguntaMultiselect from "./PreguntaMultiselect/PreguntaMultiselect";
import BotonEnviar from "../BotonEnviar/BotonEnviar"
// ============
//import PreguntaPuntaje from "./PreguntaPuntaje/PreguntaPuntaje"; // <-- Componente para mostrar el control numérico (tipoControlId = 2) con RadioBottons. En la implementación final se usa el componente Menú, que es más estético y flexible al poder mostrar números, textos, o lo que sea, sin romper la página. En cualquier caso, este otro control alternativo sigue siendo funcional (y se puede configurar para que las opciones se muestren en horizontal o vertical)
//import PreguntaEstrella from "./PreguntaEstrella/PreguntaEstrella"; // <-- Este componente puede generar la cantidad de estrellas que se pasen como valor en la entrada "Opciones" de la pregunta. Podría usarse como sustituto de tipoControlId = 2, pero habría que hacerle algunos cambios para que vuelva a ser 100% funcional.

const Preguntas = ({ interrogantes, alturaFija, datosCrudos}) => {

      // Código provisional. Hasta que Mariano incluya en la API un valor que determine si las preguntas son obligatorias o no, hacemos que todas las preguntas lo sean (salvo las de texto)

      interrogantes.forEach(obj => {
           obj.obligatoria = obj.tipoControlId !== 3;
      });

      console.log("Objeto de preguntas:")
      console.log(interrogantes)

      // Esto se utiliza para calcular la cantidad de preguntas de la encuesta, cuántas lleva respondidas el usuario, y en base a eso mostrar -o no- el botón para enviar el fetch de regreso al serviro con las respuestas
      const totalPreguntas = interrogantes.filter(obj => obj.obligatoria).length + 2; // <-- El "filter" hace que sólo se cuenten las preguntas obligatorias
      //const totalPreguntas = interrogantes.length + 2;

      const [respuestas, setRespuestas] = useState({});
      const [respuestasRespondidas, setRespuestasRespondidas] = useState(0);

           /*console.log("Se cargaron un total de " + totalPreguntas + " preguntas");*/

      // Función que gestiona cuando el usuario modifica una pregunta
      const handleOnChangeRespuesta = (numeroPregunta, nuevaRespuesta) => {

           // Actualizar el useState de las Respuestas
           setRespuestas((prevRespuestas) => {
           const updatedRespuestas = {
                ...prevRespuestas,
                [numeroPregunta]: nuevaRespuesta
           };
        
           // Contar cuántas respuestas tienen 'tieneContenido' como true
           const respondidas = Object.values(updatedRespuestas).filter(
                respuesta => respuesta.tieneContenido === true && respuesta.esObligatoria === true
           ).length;
        
           // Actualizar el estado de respuestasRespondidas
           setRespuestasRespondidas(respondidas);

                //console.log("Respuestas actuales:", updatedRespuestas); console.log("Respuestas respondidas:", respondidas);

           return updatedRespuestas;
           });
      };

      // Return para generar las preguntas 
      return (
           <div>
                {/* Si en formularioEncuesta se indica que alturaFija = true, se puede hacer que el formulario de preguntas tenga una altura fija y que, en caso de superarla, se generen barras de desplazamiento verticales. Esto está desactivado por default */}
                <div className={`${classes.pilarPreguntas} ${classes.scrollableDiv}`}
                     style={{
                          pointerEvents: alturaFija ? 'none' : 'auto',
                          maxHeight: alturaFija ? '420px' : 'none',
                          overflowY: alturaFija ? 'auto' : 'visible',
                     }}
                >
                {/* Preguntas pidiendo nombre y apellido que tiene que estar siempre */}
                     <div className={`${classes.identidad}`}>
                          <PreguntaTexto
                               textoPregunta={"Nombre (*):"}
                               fraseSugerencia=""
                               numeroPregunta={0}
                               esObligatoria={true}
                               onChangeRespuesta={(respuesta) => handleOnChangeRespuesta(0, respuesta)}
                          />
                          <PreguntaTexto
                               textoPregunta={"Apellido (*):"}
                               fraseSugerencia=""
                               numeroPregunta={1}
                               esObligatoria={true}
                               onChangeRespuesta={(respuesta) => handleOnChangeRespuesta(1, respuesta)}
                          />
                     </div>
                     {/* Map que recorre todo el array de preguntas y las genera en componentes */}
                     {interrogantes.map((entrada, index) => {
                          const numeroPregunta = index + 2;
                          return (
                               <div key={index}>
                                    {(() => {
                                         switch (entrada.tipoControlId) {
                                              case 1: // Pregunta Si/No
                                                   return <PreguntaSiNo
                                                        textoPregunta={entrada.titulo + (entrada.obligatoria ? " (*)" : "")}
                                                        defaultValue={entrada.valorDefault}
                                                        numeroPregunta={entrada.id}//{numeroPregunta}
                                                        idSI = {entrada.opciones[0].id}
                                                        idNO = {entrada.opciones[1].id}
                                                        esObligatoria={entrada.obligatoria}
                                                        onChangeRespuesta={(respuesta) => handleOnChangeRespuesta(numeroPregunta, respuesta)} 
                                                   />;
                                              case 2: // Pregunta Numerica
                                                   return <PreguntaMenu
                                                        textoPregunta={entrada.titulo + (entrada.obligatoria ? " (*)" : "")}
                                                        PreguntasOpciones={entrada.opciones}
                                                        valorDefault={entrada.valorDefault}
                                                        numeroPregunta={entrada.id}//{numeroPregunta}
                                                        esObligatoria={entrada.obligatoria}
                                                        onChangeRespuesta={(respuesta) => handleOnChangeRespuesta(numeroPregunta, respuesta)}
                                                   />;
                                              case 3: // Pregunta texto
                                                   return <PreguntaTexto
                                                        textoPregunta={entrada.titulo + (entrada.obligatoria ? " (*)" : "")}
                                                        fraseSugerencia={entrada.valorDefault}
                                                        numeroPregunta={entrada.id}//{numeroPregunta}
                                                        esObligatoria={entrada.obligatoria}
                                                        onChangeRespuesta={(respuesta) => handleOnChangeRespuesta(numeroPregunta, respuesta)}
                                                   />;
                                              case 4: // Pregunta Multiselect
                                                   return <PreguntaMultiselect
                                                        textoPregunta={entrada.titulo + (entrada.obligatoria ? " (*)" : "")}
                                                        PreguntasOpciones={entrada.opciones}
                                                        valorDefault={entrada.valorDefault}
                                                        numeroPregunta={entrada.id}//{numeroPregunta}
                                                        esObligatoria={entrada.obligatoria}
                                                        onChangeRespuesta={(respuesta) => handleOnChangeRespuesta(numeroPregunta, respuesta)}
                                                        isHorizontal={true}
                                                   />;
                                              default:
                                                   return null;
                                         }
                                    })()}
                               </div>
                          );
                     })}   
                </div>
                <div className={`${classes.enviar}`}>
                     {respuestasRespondidas === totalPreguntas ? (
                          <BotonEnviar respuestas={respuestas} datosCrudos={datosCrudos || ""}/>   
                     ) : (
                          <>
                               <p><span style={{color: "rgb(10 165 0)"}}><b>Se {respuestasRespondidas === 1 ? "respondió":"respondieron"} {respuestasRespondidas} {respuestasRespondidas === 1 ? "pregunta obligatoria (*)":"preguntas obligatorias (*)"}</b></span></p>
                               <p><b>{(totalPreguntas - respuestasRespondidas) === 1 ? "Queda":"Quedan"} {totalPreguntas - respuestasRespondidas} por responder</b></p>
                          </>
                     )}
                </div>
           </div>
      );
};

export default Preguntas;
