import * as actionTypes from './actionTypes';
import axios from '../../axios-alertas';
import { storageUsuarioId } from '../../shared/sessionData';

export const loadAlertasDelUsuario = () => {
  return (dispatch) => {
    dispatch(loadAlertasDelUsuarioStart());

    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    axios
      .get(`/notificacion/obtenerNotificaciones?idUsuario=${storageUsuarioId()}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        let data = [];
        if (response && response.data) {
          response.data.forEach((item) => {
            if (item.data) {              
              const itemData = typeof item.data === 'string' ? JSON.parse(item.data) : item.data;
              let itemNotif = Array.isArray(itemData)
                ? itemData[0] : itemData;

              item['type'] = itemNotif.type;

              if(itemNotif.payload) {
                item['empresa'] = itemNotif.payload.nombreEmpresa;

                if(itemNotif.type === 'alert') {
              item['comprobante'] = itemNotif.payload;
                }
  
                if(itemNotif.type === 'report') {
                  item['idInforme'] = itemNotif.payload.idHistorialDeEjecucion;
                }
              }              
            } else {              
              item['type'] = 'alert';
              item['comprobante'] = undefined;
            }
          });

          data = response.data;
        }

        dispatch(loadAlertasDelUsuarioSuccess(data));
      })
      .catch((error) => {
        dispatch(loadAlertasDelUsuarioFail(error));
      });
  };
};

export const marcarAlertaComoLeida = (idAlerta, leida) => {
    return (dispatch) => {  
      const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
      axios
      .put(`/notificacion/actualizarNotificacionesLeidas?idNotificacion=${idAlerta}&idUsuario=${storageUsuarioId()}`, {}, {
          headers: { Authorization: authStr }
        })
        .then(() => {
          dispatch(loadAlertasDelUsuario());
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

export const eliminarAlerta = (idAlerta) => {
    return (dispatch) => {  
      const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
      axios
      .put(`/notificacion/borrarNotificacion?idNotificacion=${idAlerta}&idUsuario=${storageUsuarioId()}`, {
          headers: { Authorization: authStr }
        })
        .then(() => {
          dispatch(loadAlertasDelUsuario());
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

const loadAlertasDelUsuarioStart = () => {
  return {
    type: actionTypes.LOAD_ALERTAS_DEL_USUARIO_START
  };
};

const loadAlertasDelUsuarioSuccess = (alertas) => {
  return {
    type: actionTypes.LOAD_ALERTAS_DEL_USUARIO_SUCCESS,
    alertas
  };
};

const loadAlertasDelUsuarioFail = (error) => {
  return {
    type: actionTypes.LOAD_ALERTAS_DEL_USUARIO_FAIL,
    error
  };
};

//#region Informes

export const loadInformesDelUsuario = (request) => {
  const { idUsuario, idEmpresa = 0, idTipoInforme = 0, leido = null, favorito = null, archivado = null,
    pagina = 0, paginaSize = 10, fechaDesde = null, fechaHasta = null } = request;

  let url = `/informes?idUsuario=${idUsuario}&idEmpresa=${idEmpresa}&idTipoInforme=${idTipoInforme}&pagina=${pagina}&paginaSize=${paginaSize}`;

  if (leido !== null) {
    url = `${url}&leido=${leido}`;
  }

  if (favorito !== null) {
    url = `${url}&favorito=${favorito}`;
  }

  if (archivado !== null) {
    url = `${url}&archivado=${archivado}`;
  }

  if (fechaDesde !== null) {
    url = `${url}&fechaDesde=${fechaDesde}`;
  }

  if (fechaHasta !== null) {
    url = `${url}&fechaHasta=${fechaHasta}`;
  }

  return (dispatch) => {
    dispatch(loadInformesDelUsuarioStart());

    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    axios
      .get(url, { headers: { Authorization: authStr } })
      .then((response) => {
        if (response && response.data) {
          dispatch(loadInformesDelUsuarioSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(loadInformesDelUsuarioFail(error));
      });
  };
}

const loadInformesDelUsuarioStart = () => {
  return {
    type: actionTypes.LOAD_INFORMES_DEL_USUARIO_START
  };
}

const loadInformesDelUsuarioSuccess = (informes) => {
  return {
    type: actionTypes.LOAD_INFORMES_DEL_USUARIO_SUCCESS,
    informes
  };
}

const loadInformesDelUsuarioFail = (error) => {
  return {
    type: actionTypes.LOAD_INFORMES_DEL_USUARIO_FAIL,
    error
  };
}

export const updateFiltrosInformes = (filtros) => {
  return {
    type: actionTypes.UPDATE_FILTROS_INFORMES_DEL_USUARIO,
    filtros
  }
}

export const descargarInforme = (id, success) => {
  return () => {
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    axios
      .get(`/informes/${id}/descargar?idUsuario=${storageUsuarioId()}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        if (response && response.data) {
          if (success)
            success(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
};
};

export const marcarInformeComoLeido = (id, filtrosInformes) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    axios
      .put(`/informes/${id}/leido?idUsuario=${storageUsuarioId()}`, {}, {
        headers: { Authorization: authStr }
      })
      .then(() => {
        dispatch(loadInformesDelUsuario(filtrosInformes));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const marcarInformeComoFavorito = (id, filtrosInformes) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    axios
      .put(`/informes/${id}/favorito?idUsuario=${storageUsuarioId()}`, {}, {
        headers: { Authorization: authStr }
      })
      .then(() => {
        dispatch(loadInformesDelUsuario(filtrosInformes));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const archivarInforme = (id, filtrosInformes) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    axios
      .put(`/informes/${id}/archivado?idUsuario=${storageUsuarioId()}`, {}, {
        headers: { Authorization: authStr }
      })
      .then(() => {
        dispatch(loadInformesDelUsuario(filtrosInformes));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const eliminarInforme = (id, filtrosInformes) => {
  return (dispatch) => {
    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    axios
      .put(`/informes/${id}/borrado?idUsuario=${storageUsuarioId()}`, {
        headers: { Authorization: authStr }
      })
      .then(() => {
        dispatch(loadInformesDelUsuario(filtrosInformes));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

//#endregion