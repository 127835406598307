// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Righteous&family=SUSE:wght@100..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Estilo global para el fondo de la página */\r\n\r\nhtml,\r\nbody {\r\n      margin: 0;\r\n      padding: 0;\r\n      height: 100%;\r\n      background-color: #e5e5e5;\r\n      /* Asegúrate de que el body ocupe toda la altura */\r\n}\r\n\r\n.EncuestasPrincipal-module__fondoGeneral__3uXO7 {\r\n      margin: 0;\r\n      min-height: 100vh;\r\n}\r\n\r\n.EncuestasPrincipal-module__fondoCentrado__ZpGmD {\r\n      margin-top: 20px;\r\n      margin-bottom: 20px;\r\n      display: flex;\r\n      justify-content: center;\r\n      align-items: flex-start;\r\n     \r\n}\r\n\r\n/* Contenedor principal */\r\n.EncuestasPrincipal-module__contenedorPrincipal__xBPDL {\r\n      width: 750px; /*750px;*/ /*900px;*/ /* Cambiar acá */\r\n      background-color: white;\r\n      text-align: center;\r\n      border-radius: 10px;\r\n      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);\r\n      font-family: \"SUSE\", sans-serif;\r\n      /*font-family: \"Righteous\", sans-serif;*/\r\n      font-optical-sizing: auto;\r\n      font-weight: 100;\r\n      font-style: normal;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fondoGeneral": "EncuestasPrincipal-module__fondoGeneral__3uXO7",
	"fondoCentrado": "EncuestasPrincipal-module__fondoCentrado__ZpGmD",
	"contenedorPrincipal": "EncuestasPrincipal-module__contenedorPrincipal__xBPDL"
};
export default ___CSS_LOADER_EXPORT___;
