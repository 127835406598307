import React, { Suspense, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Spinner from './components/UI/Spinner/Spinner';
import Encuestas from './containers/Encuestas/Encuestas';

const AppEncuestas = () => {
  useEffect(() => {
    document.title = "Encuesta de Calidad"
  }, []);

  const routes = (
    <Switch>
      <Route path="/encuestas" render={(props) => <Encuestas {...props} />} />
    </Switch>
  );

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        {routes}
      </Suspense>
    </div>
  );
};

export default withRouter(AppEncuestas);
