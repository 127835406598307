// Componente que genera la Cabecera con el logo y el título de la encuesta
// ============
import React from 'react';
// ============
import classes from './Cabecera.module.css';

export default function Cabecera({ tituloEncuesta, numeroEncuesta }) {
      // Return del componente 
      return (
           <div className={classes.cuerpito}>
                <div className={classes.container}>
                     <div className={classes.left}>
                          <img src="/img/jaliscoLogo.png" alt="Gauss" width="300px"/>
                     </div>
                <div className={classes.right}>
                     <div className={classes.letraGrande}>
                          {tituloEncuesta}
                     </div>
                     <div className={classes.letraChica}>
                          {numeroEncuesta ? `Orden de trabajo N° ${numeroEncuesta}`: ""}
                     </div>
                </div>
           </div>
           </div>
      );
};
