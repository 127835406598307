// Componente encargado de armar las preguntas de texto
// (tipoControlId = 3)
// ============
import React, { useState, useEffect } from 'react';
// ============
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function PreguntaTexto({ textoPregunta, fraseSugerencia, numeroPregunta, onChangeRespuesta, esObligatoria }) {
      
      // Estado local para almacenar el contenido del cuadro de texto
      const [texto, setTexto] = useState('');

      // Maneja el cambio del campo de texto
      const handleTextoChange = (event) => {
            const nuevoTexto = event.target.value;
            setTexto(nuevoTexto);
      };

      // Efecto para enviar la información al componente padre cada vez que el texto cambie
      useEffect(() => {
           const respuesta = {
                numeroPregunta: numeroPregunta,
                tieneContenido: texto.trim() !== '', // Devuelve true si hay contenido, false si está vacío
                contenido: [numeroPregunta, texto],
                esObligatoria: esObligatoria
           };
           onChangeRespuesta(respuesta); // Envía los datos al componente padre
      }, [texto]); // Removemos onChangeRespuesta de las dependencias

      // Return del componente
      return (
           <div>
                {textoPregunta}
                <Box
                     component="form"
                     sx={{ '& > :not(style)': { m: 1, width: '330px' } }}
                     noValidate
                     autoComplete="off"
                >
                     <TextField
                          label={fraseSugerencia}
                          id="standard-basic"
                          variant="outlined"
                          value={texto}
                          onChange={handleTextoChange}
                          sx={{ width: '330px', height: '40px' }}
                          InputProps={{ sx: { height: '40px' } }}
                     />
                </Box>
                <div><br /></div>
           </div>
      );
}
