// Componente que genera las preguntas multi-select de varias opciones
// (tipoControlId = 4)
// ============
import React, { useState, useEffect } from 'react';
// ============
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';

// El componente/función en sí mismo
export default function PreguntaMultiselect({textoPregunta, PreguntasOpciones, valorDefault = [], numeroPregunta, onChangeRespuesta, isHorizontal, esObligatoria}) {
    
      // Estado para almacenar los valores seleccionados como arrays [id, opcion]
      const [selectedValues, setSelectedValues] = useState(
           Array.isArray(valorDefault) ? valorDefault : []
      );

      // Manejador de cambio de selección
      const handleChange = (event, opcion) => {
           const value = [opcion.id, opcion.opcion];
           setSelectedValues((prevSelected) =>
                prevSelected.some(item => item[0] === opcion.id)
                ? prevSelected.filter(item => item[0] !== opcion.id) // Desmarcar si ya está seleccionado
                : [...prevSelected, value] // Agregar si no está seleccionado
           );
      };

      // Efecto para enviar la información al componente padre cada vez que cambia la selección
      useEffect(() => {
           const respuesta = {
                numeroPregunta: numeroPregunta,
                tieneContenido: selectedValues.length > 0,
                contenido: selectedValues, // Array de arrays de opciones seleccionadas
                esObligatoria: esObligatoria
           };
           onChangeRespuesta(respuesta);
      }, [selectedValues]);

      // Return del componente
      return (
           <div>
                <div>{textoPregunta}</div>
                <FormControl component="fieldset">
                     <FormGroup row={isHorizontal}>
                          {PreguntasOpciones.map((opcion) => (
                               <FormControlLabel
                                    key={opcion.id}
                                    control={
                                         <Checkbox
                                              checked={selectedValues.some(item => item[0] === opcion.id)}
                                              onChange={(event) => handleChange(event, opcion)}
                                              style={{ color: '#f1a208' }}
                                         />
                                    }
                                    label={opcion.opcion}
                               />
                          ))}
                     </FormGroup>
                </FormControl>
                <div>
                     <br />
                </div>
           </div>
      );
}