// Componente encargado de armar la página de preguntas
// ============
import React from 'react';
// ============
import classes from './formularioEncuesta.module.css';
// ============
import Cabecera from "../../../components/Encuestas/Cabecera/Cabecera";
//import MensajeBienvenida from "../../../components/Encuestas/MensajeBienvenida/MensajeBienvenida"; // <-- Este componente, 100% funcional pero al final no implementado, permite mostrar una tarjeta con un mensaje por encima de la columna de Detalles y la de Preguntas. En la implementación actual el mensaje de bienvenida se muestra en la propia tarjeta de Detalles, a la izquierda
import DetalleEncuesta from "../../../components/Encuestas/DetalleEncuesta/DetalleEncuesta";
import Preguntas from "../../../components/Encuestas/Preguntas/Preguntas";
import Publicidad from "../../../components/Encuestas/Publicidad/Publicidad";
import MensajeInfoerror from '../MensajeInfoerror/MensajeInfoerror';

const FormularioEncuesta = ({ datos }) => {
      // Verificar si hay preguntas en la encuesta
      const tienePreguntas = datos?.encuesta?.preguntas && datos.encuesta.preguntas.length > 0;

      // Return
      return (
           <>
                {datos.contestada ? (
                     <>
                          {(() => {
                               const mensaje = { // Mensaje que se muestra si la encuesta solicitada ya está respondida
                                    titulo: "¡Encuesta respondida!",
                                    texto1: "Gracias por tu feedback",
                                    texto2: datos?.encuesta?.cierre ? (datos?.encuesta?.cierre) : (""),
                                    imagen: 2,
                               };
                               return <MensajeInfoerror mensaje={mensaje} />;
                          })()}
                     </>
                ) : (
                     <>
                          {tienePreguntas ? ( // Generamos la pagina con la cabecera, la tarjeta de detalles, la zona de prguntas y el footer
                               <>
                                    <div className={classes.header}>
                                         <Cabecera tituloEncuesta={datos?.encuesta?.titulo || ""}  numeroEncuesta={datos?.soporte?.numero || ""} />
                                    </div>
                                    {/*<MensajeBienvenida texto={datos?.encuesta?.introduccion || ""} />*/}
                                    <div className={classes.columnas}>
                                         <div className={classes.columnaIzq}>
                                              <DetalleEncuesta 
                                                   mensajeBienvenida={datos?.encuesta?.introduccion || ""} 
                                                   numeroEncuesta={datos?.soporte?.numero || ""}
                                                   fechaSolicitud={datos?.soporte?.fecha || ""} 
                                                   fechaCierre={datos?.soporte?.fechaResolucion || ""} 
                                                   cliente={datos?.soporte?.nombreCliente || ""} 
                                                   SolicitadaPor={datos?.soporte?.usuarioCliente || ""} 
                                                   RealizadaPor={datos?.soporte?.responsable || ""} 
                                                   Tema={datos?.soporte?.tarea || ""} 
                                                   Detalle={datos?.soporte?.detalle || ""} 
                                              />
                                         </div>
                                         <div className={classes.columnaDer}>
                                              <Preguntas interrogantes={datos.encuesta.preguntas} alturaFija={false} datosCrudos={datos || ""} />
                                         </div>
                                    </div>
                                    <div className={classes.footer}>
                                         <Publicidad ocultar={0} />
                                    </div>
                               </>
                          ) : (
                               <>
                                    {(() => { // Mensaje de error si la pregunta existe y no está respondida, pero el array de preguntas está vacío 
                                         const mensaje = {
                                              titulo: "La encuesta está vacía",
                                              texto1: "Si estás viendo este mensaje, reportalo al soporte técnico",
                                              imagen: 1,
                                         };
                                         return <MensajeInfoerror mensaje={mensaje} />;
                                    })()}
                               </>
                          )}
                     </>
                )}
           </>
      );
};
export default FormularioEncuesta;