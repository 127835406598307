// Componente que genera las preguntas Si/No
// (tipoControlId = 1)
// ============
import React, { useState, useEffect } from 'react';
// ============
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function PreguntaSiNo({ textoPregunta, defaultValue, numeroPregunta, onChangeRespuesta, idSI, idNO, esObligatoria}) {

      // Determinamos el valor inicial basado en defaultValue
      const initialValue = (defaultValue === 0) ? `${idSI}_Si` : (defaultValue === 1) ? `${idNO}_No` : '';
      const [selectedValue, setSelectedValue] = useState(initialValue);

      // Manejador de cambio de selección
      const handleChange = (event) => {
           const value = event.target.value;
           if (value !== selectedValue) { // Solo actualiza si el valor es diferente
                setSelectedValue(value);
           }
      };

      // Efecto para enviar la información al componente padre cada vez que la selección cambie
      useEffect(() => {
           const tieneContenido = selectedValue !== ''; // Devuelve true si hay contenido
           const [id, respuesta] = selectedValue.split('_'); // Extraemos el id y la respuesta
           const respuestaObj = {
                numeroPregunta: numeroPregunta,
                tieneContenido: tieneContenido,
                contenido: [id, respuesta],
                id: id,
                esObligatoria: esObligatoria
           };
           onChangeRespuesta(respuestaObj); // Envía los datos al componente padre
      }, [selectedValue]); // Evitamos bucles pasando el valor correcto

      // Return del componente
      return (
           <div>
                <div>{textoPregunta}</div>
                <FormControl>
                     <RadioGroup
                          row
                          value={selectedValue}
                          onChange={handleChange}
                          style={{ justifyContent: 'space-between' }} // Justifica los controles
                     >
                          <FormControlLabel
                               value={`${idSI}_Si`}
                               control={<Radio style={{ color: '#f1a208' }} />}
                               label="Sí"
                          />
                          <FormControlLabel
                               value={`${idNO}_No`}
                               control={<Radio style={{ color: '#f1a208' }} />}
                               label="No"
                          />
                     </RadioGroup>
                </FormControl>
                <div><br /></div>
           </div>
      );
}