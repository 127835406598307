// Componente que genera las preguntas de calificación de una sola opción seleccionable (usando un menú)
// (tipoControlId = 2)
// ============
import React, { useState, useEffect } from 'react';
// ============
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function PreguntaMenu({ textoPregunta, PreguntasOpciones, valorDefault, numeroPregunta, onChangeRespuesta, esObligatoria}) {
      
      // Estado para almacenar el valor seleccionado
      const [selectedValue, setSelectedValue] = useState(
           PreguntasOpciones[valorDefault]?.id || ''
      );

      // Manejador de cambio de selección
      const handleChange = (event) => {
           setSelectedValue(event.target.value);
      };

      // Efecto para enviar la información al componente padre cada vez que se selecciona una opción
      useEffect(() => {
           const tieneContenido = selectedValue !== ''; // Devuelve true si hay una opción seleccionada
           const opcionSeleccionada = PreguntasOpciones.find(opcion => opcion.id === parseInt(selectedValue)); // Encuentra la opción seleccionada
           const contenido = opcionSeleccionada ? [opcionSeleccionada.id, opcionSeleccionada.opcion] : null; // Devuelve el array con id y opcion, o null si no hay opción seleccionada
           const respuesta = {
                numeroPregunta: numeroPregunta,
                tieneContenido: tieneContenido,
                contenido: contenido, // Array con el formato deseado
                esObligatoria: esObligatoria
           };
           onChangeRespuesta(respuesta);
      }, [selectedValue, PreguntasOpciones]); // Dependencias del efecto

      // Return del componente
      return (
           <div>
                <div>{textoPregunta}</div>
                <FormControl 
                     fullWidth 
                     sx={{ 
                          '& > :not(style)': { m: 1 }, 
                          width: '330px', 
                          height: '40px' 
                     }}
                >
                     <Select
                          displayEmpty
                          value={selectedValue}
                          onChange={handleChange}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                               if (selected === '') {
                                    return <em>Seleccione una opción</em>;
                               }
                               return PreguntasOpciones.find(opcion => opcion.id === parseInt(selected))?.opcion;
                          }}
                          inputProps={{ 'aria-label': 'Without label' }}
                          sx={{ 
                               width: '330px', 
                               height: '40px',
                               display: 'flex',
                               alignItems: 'center'
                          }}
                     >
                          <MenuItem disabled value="">
                               <em>Seleccione una opción</em>
                          </MenuItem>
                          {PreguntasOpciones.map((opcion) => (
                               <MenuItem key={opcion.id} value={opcion.id}>
                                    {opcion.opcion}
                               </MenuItem>
                          ))}
                     </Select>
                </FormControl>      
                <div>
                     <br />
                </div>
           </div>
      );
}
