import React, { Suspense, useCallback, useState, useEffect, useRef, Fragment } from 'react';
import { Route, Switch, Redirect, withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';

import { pathNamePages } from './shared/pathnames';

import Spinner from './components/UI/Spinner/Spinner';
import Login from './containers/Auth/Login/Login';
import FotoDelDia from './containers/FotoDelDia/FotoDelDia';
import Gastos from './containers/Gastos/Gastos';
import Logout from './containers/Auth/Logout/Logout';
import Multiempresa from './containers/Multiempresa/Multiempresa';
import TiemposReq from './containers/TiemposReq/TiemposReq';
import ChangeLogs from './containers/ChangeLogs/ChangeLogs';
import SimpleBackdrop from './components/UI/Backdrop/Backdrop';
import esMessage from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import Rentabilidad from './containers/Rentabilidad/Rentabilidad';
import IframePortal from './containers/Auth/IframePortal/IframePortal';
import AnalisisDeVentas from './containers/AnalisisDeVentas/AnalisisDeVentas';
import AnalisisDeVendedores from './containers/AnalisisDeVendedores/AnalisisDeVendedores';
import AnalisisDeSucursales from './containers/AnalisisDeSucursales/AnalisisDeSucursales';
import AnalisisDeUtilidad from './containers/AnalisisDeUtilidad/AnalisisDeUtilidad';
import Novedades from './components/Configuraciones/Novedades/Novedades';
import { getUserInfo, storageToken, updateUserInfo } from './shared/sessionData';
import PanelNoganet from './containers/PanelNoganet/PanelNoganet';
import PanelTenacta from './containers/Tenacta/PanelTenacta/PanelTenacta';
import InformesTenacta from './containers/Tenacta/InformesTenacta/InformesTenacta';
import InformeDos from './containers/Tenacta/InformeDos/InformeDos';
import InformeTres from './containers/Tenacta/InformeTres/InformeTres';
import Informes from './containers/Informes/Informes';

const Layout = React.lazy(() => {
  return import('./containers/Layout/Layout');
});
const PantallaCarga = React.lazy(() => {
  return import('./containers/PantallaCarga/PantallaCarga');
});

const Dashboard = React.lazy(() => {
  return import('./containers/Dashboard/Dashboard');
});

const GridVentas = React.lazy(() => {
  return import('./components/Dashboard/GridVentas/GridVentas');
});

const AppDashboard = (props) => {
  useEffect(() => {
    document.title = "Jalisco Dashboard"
  }, []);
  const { onTryAuthSignin, login, onLoadIndicadoresOcultos, onShowNovedades, onLoadAlertas } = props;
  const { pathname, search } = useLocation();
  const [userInfo, setUserInfo] = useState();
  const [secciones, setSecciones] = useState([]);

  const obtenerSecciones = useCallback((userInfo) => {
    const items = [];
    if (
      userInfo !== null &&
      userInfo !== undefined &&
      userInfo.perfil !== null &&
      userInfo.perfil !== undefined &&
      userInfo.perfil.perfilAcceso !== null &&
      userInfo.perfil.perfilAcceso !== undefined
    ) {
      const perfilesAccesos = userInfo.perfil.perfilAcceso;
      const filtrados = perfilesAccesos.filter((pa) => pa.aplicacionKey === 'dashboard');

      if (filtrados) filtrados.forEach((fil) => items.push(fil));
    }
    setSecciones(items);
  }, []);


  const mostrarNovedades = useCallback(() => {
    if (userInfo.tieneNovedades !== 'undefined' && userInfo.tieneNovedades === true) {
      onShowNovedades(true);
      const newUserInfo = { ...userInfo, tieneNovedades: false };
      updateUserInfo(newUserInfo);
    }
  }, [onShowNovedades, userInfo]);

  useEffect(() => {
    if (userInfo === null || userInfo === undefined || userInfo.id === null || userInfo.id === undefined) {
      const item =
        props.userInfo !== null &&
          props.userInfo !== undefined &&
          props.userInfo.id !== null &&
          props.userInfo.id !== undefined
          ? props.userInfo
          : getUserInfo();
      setUserInfo(item);
      obtenerSecciones(item);
    }
    else
      mostrarNovedades();
  }, [props.userInfo, userInfo, setUserInfo, obtenerSecciones, mostrarNovedades]);

  const tieneAcceso = (key) => {
    const seccion = secciones.find((sec) => sec.key === key);
    return seccion !== null && seccion !== undefined;
  };

  const getDefaultPath = () => {
    const seccion = secciones[0];

    if (!seccion) return '/fotoDelDia';

    switch (seccion.key) {
      case 'fotodeldia':
        return '/fotoDelDia';

      case 'analisisdegastos':
        return '/gastos';

      case 'dashboard':
        return '/dashboard';

      case 'ventas':
        return '/ventas';

      case 'multiempresa':
        return '/multiempresa';

      case 'resultado':
        return '/rentabilidad';

      case 'analisisdeventas':
        return '/analisisventas';

      case 'analisisdesucursales':
        return '/analisisSucursales';

      case 'analisisdevendedores':
        return '/analisisVendedores';

      case 'analisisdeutilidad':
        return '/analisisUtilidad';

      default:
        return;
    }
  };

  useEffect(() => {
    if (storageToken()) {
      onTryAuthSignin();
    }
  }, [onTryAuthSignin]);

  useEffect(() => {
    if (!storageToken()) {
      login(search.slice(1));
    }
  }, [login, search]);

  useEffect(() => {
    loadMessages(esMessage);
    locale(navigator.language);
  }, []);

  useEffect(() => {
    if (props.token !== null && props.token !== undefined)
      onLoadIndicadoresOcultos(props.token);
  }, [props.token, onLoadIndicadoresOcultos]);

  useEffect(() => {
    if (props.token !== null && props.token !== undefined) {
      onLoadIndicadoresOcultos(props.token);
      onLoadAlertas();
    }
  }, [props.token, onLoadIndicadoresOcultos, onLoadAlertas]);

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(() => {
      onLoadAlertas();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [onLoadAlertas]);

  let routes = (
    <Switch>
      <Route path="/" component={PantallaCarga} />
    </Switch>
  );

  const fotoDelDiaRef = useRef();

  if (props.token !== null && !props.loadingAuth)
    routes = (
      <Switch>
        <Route exact path="/logout" component={Logout} />
        {tieneAcceso('homenoganet') && <Route path="/Noganet" render={(props) => <PanelNoganet {...props} />} />}
        {tieneAcceso('hometenacta') && <Route path="/Tenacta" render={(props) => <PanelTenacta {...props} />} />}
        {tieneAcceso('informestenacta') && (
          <Route path="/tenactaInformes" render={(props) => <InformesTenacta {...props} />} />
        )}
        {tieneAcceso('informestenacta') && (
          <Route path="/tenactaInformeDos" render={(props) => <InformeDos {...props} />} />
        )}
        {tieneAcceso('informestenacta') && (
          <Route path="/tenactaInformeTres" render={(props) => <InformeTres {...props} />} />
        )}
        {tieneAcceso('fotodeldia') && (
          <Route
            path="/fotoDelDia"
            render={(props) => <FotoDelDia {...props} indicadoresOcultos={props.indicadoresOcultos} />}
          />
        )}
        {tieneAcceso('analisisdegastos') && <Route path="/gastos" render={(props) => <Gastos {...props} />} />}
        {tieneAcceso('dashboard') && <Route path="/dashboard" render={(props) => <Dashboard {...props} />} />}
        {tieneAcceso('ventas') && <Route path="/ventas" render={(props) => <GridVentas {...props} />} />}
        {tieneAcceso('multiempresa') && <Route path="/multiempresa" render={(props) => <Multiempresa {...props} />} />}
        {tieneAcceso('resultado') && <Route path="/rentabilidad" render={(props) => <Rentabilidad {...props} />} />}
        {tieneAcceso('analisisdeventas') && (
          <Route path="/analisisVentas" render={(props) => <AnalisisDeVentas {...props} />} />
        )}
        {tieneAcceso('analisisdesucursales') && (
          <Route
            path="/analisisSucursales"
            ref={fotoDelDiaRef}
            render={(props) => <AnalisisDeSucursales {...props} />}
          />
        )}
        {tieneAcceso('analisisdevendedores') && (
          <Route
            path="/analisisVendedores"
            ref={fotoDelDiaRef}
            render={(props) => <AnalisisDeVendedores {...props} />}
          />
        )}
        {tieneAcceso('analisisdeutilidad') && (
          <Route path="/analisisUtilidad" render={(props) => <AnalisisDeUtilidad {...props} />} />
        )}
        {props.userInfo.isAdmin && <Route path="/changeLogs" render={(props) => <ChangeLogs {...props} />} />}
        {props.userInfo.gauss && <Route path="/tiemposReq" render={(props) => <TiemposReq {...props} />} />}
        {<Route path="/informes" render={(props) => <Informes {...props} />} />}
        {props.token !== null ? <Redirect push to={getDefaultPath()} /> : <Login />}
      </Switch>
    );

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <SimpleBackdrop
            open={
              props.loadingCambiarEmpresa ||
              props.loadingCambiarMoneda ||
              props.loadingExecuteCambiarAjustaPorInflacion ||
              props.loadingCambiarSucursal
            }
          />
          {(pathname === pathNamePages.fotoDelDia || pathname === '') && (
            <SimpleBackdrop open={props.loadingIndicadoresOcultos || props.loadingCambiarEmpresa} />
          )}
          {routes}
        </Layout>
        <IframePortal />
      </Suspense>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userInfo: state.auth.userInfo,
    loadingAuth: state.auth.loading,
    indicadoresOcultos: state.configuraciones.indicadoresPorUsuario.indicadores,
    loadingIndicadoresOcultos: state.configuraciones.indicadoresPorUsuario.loading,
    loadingCambiarEmpresa: state.multiempresa.loadingCambiarEmpresa,
    loadingCambiarMoneda: state.moneda.loadingCambiarMoneda,
    loadingExecuteCambiarAjustaPorInflacion: state.configuraciones.executeCambiarAjustaPorInflacion,
    loadingCambiarSucursal: state.sucursales.loadingCambiarSucursal
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAuthSignin: () => dispatch(actions.authCheckState()),
    login: (token) => dispatch(actions.login(token)),
    onLoadIndicadoresOcultos: (token) => dispatch(actions.loadIndicadoresOcultos(token)),
    onShowNovedades: (show) => dispatch(actions.showGlobalModal(show, undefined, <Novedades />)),
    onLoadAlertas: () => dispatch(actions.loadAlertasDelUsuario()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppDashboard));

