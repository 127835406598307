// Componente que genera el botón para Enviar las respuesta de la encuesta
// ============
import React from 'react';
// ============
import classes from './BotonEnviar.module.css';
// ============
import Button from '@mui/material/Button';
import {useDispatch, useSelector} from 'react-redux'; 
import {enviarRespuestasEncuesta} from '../../../store/actions/encuestas'; // Importamos la acción

const BotonEnviar = ({ respuestas, datosCrudos }) => {
      
      // Obtener los valores de hashEncuesta, envioFallido y detalleEnvio desde Redux
      const dispatch = useDispatch();
      const hashEncuesta = useSelector((state) => state.encuestas.hashEncuesta); 
      const envioFallido = useSelector((state) => state.encuestas.envioFallido);
      const detalleEnvio = useSelector((state) => state.encuestas.detalleEnvio);

      // Función que maneja el clic del botón
      const handleEnviar = () => {
           // Convertimos el objeto de respuestas en un array para procesarlo
           const respuestasArray = Object.entries(respuestas);
           const resultado = respuestasArray.map(([numeroPregunta, contenido]) => ({
                encuestaPreguntaId: contenido.numeroPregunta,
                encuestaPreguntaOpcionId: parseInt(contenido.contenido[0]),
                valor: contenido.contenido[1]
           }));

                //console.log("Valor de resultado[0]"); console.log(resultado[0])

           // Construimos el objeto con los datos a enviar
           let data = {
                hash: hashEncuesta,
                encuestaId: datosCrudos?.encuesta?.id,
                nombreUsuario: resultado[0].valor,
                apellidoUsuario: resultado[1].valor, 
                respuestasPreguntas: resultado.slice(2)
           };

                //console.log("El objeto para enviar en el fetch de regreso es:"); console.log(data)
        
           // Data de prueba
           /*
           data = {
                hash: hashEncuesta, //"5B5974EF-2F21-4D8F-B8F8-2A60EFCF9DEG",
                encuestaId: 1,
                nombreUsuario: "Test",
                apellidoUsuario: "Test",
                respuestasPreguntas: [
                {
                     encuestaPreguntaId: 1,
                     encuestaPreguntaOpcionId: 1,
                     valor: "OPCIÓN 1"
                },
                {
                     encuestaPreguntaId: 2,
                     encuestaPreguntaOpcionId: 7,
                     valor: "OPCIÓN 2"
                },
                {
                      encuestaPreguntaId: 3,
                     encuestaPreguntaOpcionId: 12,
                     valor: "OPCIÓN 3"
                },
                {
                     encuestaPreguntaId: 4,
                     encuestaPreguntaOpcionId: 17,
                     valor: "OPCIÓN 5"
                },
                {
                     encuestaPreguntaId: 5,
                     encuestaPreguntaOpcionId: 0,
                     valor: "TEXTO ESCRITO POR EL USUARIO"
                }
                ]
           }*/

           // Despachar la acción de enviar respuestas
           dispatch(enviarRespuestasEncuesta(data));

           // Guardar el contenido del envío en Redux
           dispatch({
                type: 'GUARDAR_CONTENIDO_ENVIO',
                contenido: data // Guardamos el objeto que se va a enviar
           });
      };

      // Return del componente
      return (
           <div className={classes.divEnviar}>
                {/* Si envioFallido es 1, mostramos el mensaje de error 
                -- en la práctica esto está depreciado, porque en caso de error
                se va a cargar la página a pantalla completa mostrando el error -- */}
                {envioFallido === 1 && (
                     <div className={classes.errorEnvio}>
                          <p style={{ color: 'red' }}>Se produjo un error al enviar la respuesta:</p>
                          <p>{detalleEnvio}</p>
                     </div>
                )}
                <Button
                     variant="contained"
                     sx={{ 
                          width: '100px', 
                          backgroundImage: 'linear-gradient(45deg, rgb(251, 176, 64), rgb(241, 90, 41))', 
                          color: 'white', '&:hover': { backgroundColor: '#083953' } 
                     }}
                     onClick={handleEnviar}
                >
                     Enviar
                </Button>
           </div>
      );
};

export default BotonEnviar;