// Componente padre principal de la sub-página de Encuestas
// ============
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// ============
import { useDispatch, useSelector } from 'react-redux';
import { guardarDatosEncuesta, cambiarEstadoPagina, obtenerDatosEncuesta, actualizarHashEncuesta } from '../../store/actions/encuestas';
// ============
import classes from './EncuestasPrincipal.module.css';
// ============
import FormularioEncuesta from './formularioEncuesta/formularioEncuesta';
import MensajeInfoerror from './MensajeInfoerror/MensajeInfoerror';
// ============ JSONs locales para testeos
//import encuestaTestA from './JSONlocalesDePrueba/encuestaTestA.json';
//import encuestaTestB from './JSONlocalesDePrueba/encuestaTestB.json';
//import encuestaTestC from './JSONlocalesDePrueba/encuestaTestC.json';
//import encuestaTestD from './JSONlocalesDePrueba/encuestaTestC.json';
//import encuestaTestE from './JSONlocalesDePrueba/encuestaTestE.json';

const EncuestasPrincipal = () => {
      const location = useLocation();
      const dispatch = useDispatch();
      const estadoPagina = useSelector((state) => state.encuestas.estadoPagina);
      const datosEncuesta = useSelector((state) => state.encuestas.datosEncuesta);
      const detalleEnvio = useSelector((state) => state.encuestas.detalleEnvio);
      const contenidoEnvio = useSelector((state) => state.encuestas.contenidoEnvio); 

           /*console.log("estadoPagina vale:"); console.log(estadoPagina); console.log("datosEncuesta vale:"); console.log(datosEncuesta); console.log("detalleEnvio vale:"); console.log(detalleEnvio); console.log("contenidoEnvio vale:"); console.log(contenidoEnvio);*/
 
      // Extraer el hash de la URL
      const queryParams = new URLSearchParams(location.search);
      //const hash = "5B5974EF-2F21-4D8F-B8F8-2A60EFCF9DEG" <-- Forzar un hash, para testeo
      const hash = queryParams.get('hash');
      dispatch(actualizarHashEncuesta(hash))

      // Fetch inicial
      useEffect(() => {
           dispatch(obtenerDatosEncuesta(hash)); // Pasar el hash a la acción en Redux
           //cargarJSON(encuestaTestC); //<-- Cargar un JSON local, para testeo
      }, [dispatch, hash]);

      // Función de testeo para cargar JSON locales
      const cargarJSON = (datos) => {
           dispatch(guardarDatosEncuesta(datos));
           dispatch(cambiarEstadoPagina(1));
      };

      // Return del componente
      return (
           <div className={classes.fondoGeneral}>
                <div className={classes.fondoCentrado}>
                     <div className={classes.contenedorPrincipal}>
                          {/* estadoPagina:
                               0 = Cargando encuesta
                               1 = La encuesta en si
                               2 = Error al cargar la encuesta 
                               3 = Mensaje que se muestra al enviar la encuesta de regreso de forma exitosa
                               4 = Mensaje que se muestra cuando ocurre un error al enviar la encuesta de regreso
                          */}
                          {estadoPagina === 0 && (
                               <>
                                    {(() => {
                                         const mensaje = {
                                              titulo: "Cargando encuesta...",
                                              texto1: "Por favor espere",
                                              imagen: 0,
                                         };
                                    return <MensajeInfoerror mensaje={mensaje} />;
                                    })()}
                               </>
                          )}
                          {estadoPagina === 1 && <FormularioEncuesta datos={datosEncuesta} />}
                          {estadoPagina === 2 && (
                               <> 
                                    {(() => {
                                         const textoError = datosEncuesta?.error ? (datosEncuesta.error) : ("Sin datos");
                                         const mensaje = {
                                              titulo: "¡Ups!",
                                              texto1: "No se pudo cargar la encuesta",
                                              error: { texto: textoError, color: "red" },
                                              texto2: "Por favor, intenta nuevamente más tarde",
                                              imagen: 1,
                                         };
                                         return <MensajeInfoerror mensaje={mensaje} />;
                                    })()}   
                               </>
                          )}
                          {estadoPagina === 3 && (
                               <> 
                                    {(() => {
                                         const mensaje = {
                                         titulo: "¡Encuesta respondida!",
                                         texto1: "Gracias por tu feedback",
                                         texto2: datosEncuesta?.encuesta?.cierre ? (datosEncuesta?.encuesta?.cierre) : (""),
                                         imagen: 2,
                                    };
                                    return <MensajeInfoerror mensaje={mensaje} />;
                                    })()}   
                               </>
                          )}
                          {estadoPagina === 4 && (
                               <> 
                                    {(() => {
                                         const textoEnvio = detalleEnvio ? (detalleEnvio) : ("Sin datos");
                                         const mensaje = {
                                              titulo: "¡Ups!",
                                              texto1: "No se pudo enviar la encuesta",
                                              error: { texto: textoEnvio, color: "red" },
                                              mostrarBotonReenviar: true,
                                              imagen: 1,
                                         };
                                         return <MensajeInfoerror mensaje={mensaje} />;
                                    })()}
                               </>
                          )}
                     </div>
                </div>
           </div>
      );
};

export default EncuestasPrincipal;
